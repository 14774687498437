import useJwt from "@/auth/jwt/useJwt"
import axios from '@/libs/axios'
import app from '@/libs/app'

export default class httpConfigHelper {
    useAxios() {
        return axios
    }

    headerConfig(...action) {
        var accessToken = useJwt.getToken()
        var actionHeaderMethod = useJwt.getAction()
        var authActionHeaderMethod = useJwt.getAuthAction()
        var header = new Headers()

        if (accessToken) {
            if (action.length > 0) {
                // If a custom action is sent, use the included JWT setAction function to override the current
                // App-Action-Header value.
                useJwt.setAction(action)
                var actionHeaderMethod = useJwt.getAction()
            }
            header = { 'App-Action-Header': `${actionHeaderMethod}`, 'App-Action-Auth': `${authActionHeaderMethod}` }
            header.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`
        }
        else {
            header = { 'App-Action-Auth': `${authActionHeaderMethod}` }
        }

        return header
    }

    async fetchRequest(url, data, options) {
        var opts = {
            method: 'POST',
            headers: options,
            body: data
        }
        const response = await fetch(url, opts)
        return response.json()
    }

    urlBuilder(endpoint) {
        const base = app.baseURL
        return base + endpoint
    }

    removeCustomStorageKeys() {
        window.localStorage.removeItem('cart')
    }
}