<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ $appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <transition appear name="fade" mode="out-in">
            <!-- OTP component -->
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto" v-if="showOtpForm" key="otp">
                <o-t-p @submit-otp="otpSubmitted($event)" @resend-otp="otpResend" :showSubmitOverlay="showSubmitOverlay"/>
            </b-col>

            <!-- Registration form component -->
            <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
            v-if="!showOtpForm"
            key="regform"
            >
                <registration-form @submit-registration="registrationSubmit($event)" @toggle-overlay="toggleOverlay" :showSubmitOverlay="showSubmitOverlay"/>
            </b-col>
        </transition>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BFormRadioGroup
} from 'bootstrap-vue'
import { required, email, confirmed, alpha } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import Register from '../../../../libs/services/register'
import OTP from './OTP.vue'
import RegistrationForm from './RegistrationForm.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    // validations
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    // components
    OTP,
    RegistrationForm,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
        registrationForm: {
            status: '',
            mobile: '',
            firstname: '',
            lastname: '',
            gender: '',
            userEmail: '',
            password: '',
            verification_code: ''
        },
        confirmPassword: '',
        genderOptions: [
            { text: 'Male', value: 'male', disabled: false },
            { text: 'Female', value: 'female', disabled: false },
        ],
        sideImg: require('@/assets/images/pages/register-v2.svg'),
        otpCheckModal: '',
        // validation
        required,
        email,
        confirmed,
        alpha,
        registration: new Register,
        showOtpForm: null,
        showSubmitOverlay: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {},
  async mounted() {
    //   setTimeout(() => {
    //     this.showOtpForm = (store.state.registrationForm.isRegistering) ? true : false
    //   }, 1000)
    await this.$store.dispatch('getTempRegistrationData').then(response => {
        if (response) {
            let data = response.form
            this.showOtpForm = true
            let textHead = 'A registration attempt for the mobile number '
            let textMobile = '+' + data.mobile
            let textTail = ' is awaiting completion. Would you like to continue registering for this mobile number, or discard this and start again?'
            let message = [textHead, textMobile, textTail].join('')
            this.showConfirmModal(message)
        }
    })
  },
  methods: {
    // Event-triggered methods
    showConfirmModal(message) {
        this.otpCheckModal = ''
        this.$bvModal.msgBoxConfirm(message, {
            title: 'Pending Registration',
            size: 'md',
            okVariant: 'success',
            okTitle: 'Yes, continue.',
            cancelVariant: 'danger',
            cancelTitle: 'No, discard this.',
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true
        }).then(data => {
            this.otpCheckModal = data
            
            // At this point, the modal should have been displayed.
            // The prompt's result will be stored in the variable above.
            if (this.otpCheckModal) {
                // Continue
            }
            else {
                // Discard
                this.$store.dispatch('discardTempRegistration')
                window.location.reload()
            }
        })
    },
    toggleOverlay() {
        this.showSubmitOverlay = !this.showSubmitOverlay
    },
    registrationSubmit(form) {
        console.log('submit-registration event: ', form)
        // On event emit, assign the sent data from the component.
        this.registrationForm = form
        // On event emit, show the button overlay.
        this.showSubmitOverlay = true

        // Then chain a request for OTP.
        let otpForm = {
            mobile: this.registrationForm.mobile
        }
        this.registration.requestOtp(otpForm).then(response => {
            let res = response.data
            if (res.result) {
                // If result is true, swap the form component with the OTP component.
                this.showOtpForm = true
                let otpData = {status: true, form: JSON.stringify(this.registrationForm)}
                this.$store.dispatch('flagAsRegistering', otpData)
                // store.commit('update_register_state_otp', true)

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'OTP Code Sent',
                        text: 'Kindly check and enter the OTP code we have sent to your mobile number.',
                        icon: 'EditIcon',
                        variant: 'info'
                    }
                })

                // Dismiss the overlay.
                this.toggleOverlay()
            }
            else {
                if (res.data) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Resend Timeout',
                            text: res.message,
                            icon: 'EditIcon',
                            variant: 'warning'
                        }
                    })
                    this.toggleOverlay()
                }
                else {
                    Object.entries(res.message).forEach(item => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Invalid ' + item[0],
                                text: item[1][0],
                                icon: 'EditIcon',
                                variant: 'warning'
                            }
                        })
                    })
                    this.toggleOverlay()
                }
            }
        }).catch(ex => {
            console.log('Error requesting OTP: ', ex)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Something went wrong',
                    text: ex.toString(),
                    icon: 'EditIcon',
                    variant: 'danger'
                }
            })
            this.toggleOverlay()
        })
    },
    async otpSubmitted(otpCode) {
        // console.log('submit-otp event: ', otpCode.verification_code)
        if (this.showOtpForm) {
            await this.$store.dispatch('getTempRegistrationData').then(response => {
                if (response) {
                    this.registrationForm = response.form
                }
            })
        }
        // On event emit, update the current registration form with the verification code.
        this.registrationForm.verification_code = otpCode.verification_code
        this.showSubmitOverlay = true

        // Then chain a request for registration.
        this.registration.register(this.registrationForm).then(response => {
            console.log(response.data)
            let res = response.data
            if (res.result) {
                // Flip the registration form's state.
                store.dispatch('checkForOtpMode', null)

                // Reroute user to login page if not pushed from checkout, else redirect to checkout window.
                if (store.state.registrationForm.isCheckingOut) {
                    // TO-DO: add code to redirect to checkout page
                    // As per login flow, a token should be set before redirecting to checkout.
                    // Consult Login.vue and see if it could be applied in the registration service.
                }
                else {
                    // Reroute to login.
                    this.$router.replace('/login').then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Registration Successful',
                                text: 'You may now login using your account.',
                                icon: 'CheckCircleIcon',
                                variant: 'success'
                            }
                        })
                    }).catch(ex => {
                        console.log('Cannot redirect to Login: ', ex)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Cannot redirect to Login',
                                text: 'Refresh page to redirect. Error: ' + ex.toString(),
                                icon: 'EditIcon',
                                variant: 'danger'
                            }
                        })
                    })

                    // Regardless if user could be redirected to the login page,
                    // remove the temp form from storage.
                    this.$store.dispatch('discardTempRegistration')
                }
                this.toggleOverlay()
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Invalid OTP form',
                        text: res.message,
                        icon: 'EditIcon',
                        variant: 'warning'
                    }
                })
                this.toggleOverlay()
            }
        }).catch(ex => {
            console.log(ex)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Something went wrong',
                    text: ex,
                    icon: 'EditIcon',
                    variant: 'warning'
                }
            })
            this.toggleOverlay()
            // this.$emit('toggle-overlay')
        })
    },
    async otpResend() {
        // console.log('resend-otp event')
        this.toggleOverlay()
        let otpForm = {}
        if (this.showOtpForm) {
            await this.$store.dispatch('getTempRegistrationData').then(response => {
                if (response) {
                    otpForm.mobile = response.form.mobile
                }
            })
        }
        else {
            otpForm.mobile = this.registrationForm.mobile
        }
        this.registration.requestOtp(otpForm).then(response => {
            let res = response.data
            if (res.result) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'OTP Code Resent',
                        text: 'Kindly check and enter the OTP code we have sent to your mobile number.',
                        icon: 'EditIcon',
                        variant: 'info'
                    }
                })
                this.toggleOverlay()
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Cannot resend OTP',
                        text: res.message,
                        icon: 'EditIcon',
                        variant: 'danger'
                    }
                })
                this.toggleOverlay()
            }
        }).catch(ex => {
            console.log('Error requesting OTP: ', ex)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Something went wrong',
                    text: ex.toString(),
                    icon: 'EditIcon',
                    variant: 'danger'
                }
            })
            this.toggleOverlay()
        })
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
