<template>
    <div>
        <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            OTP Entry
        </b-card-title>
        <b-card-text class="mb-2">
            Enter the code sent to your mobile number.
        </b-card-text>

        <!-- OTP Form -->
        <validation-observer ref="otpForm">
            <b-form class="auth-register-form mt-2">
                <b-form-group label="Code" label-for="otp-code">
                    <validation-provider #default="{ errors }" name="Code" rules="required|integer">
                        <b-form-input id="otp-code" name="otp-code" v-model="otpForm.verification_code" :state="errors.length > 0 ? false:null" placeholder="5-digit code"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-overlay :show="showSubmitOverlay" rounded="sm" spinner-variant="primary" spinner-small class="d-inline-block container-fluid p-0">
                    <b-button variant="primary" block type="submit" @click.prevent="submitOtp">Submit</b-button>
                </b-overlay>

                <div class="col-12 text-center mt-1 p-0">
                    <b-overlay :show="showSubmitOverlay" rounded="sm" spinner-variant="primary" spinner-small class="d-inline-block p-0">
                        <b-button variant="flat-secondary" @click.prevent="resendOtp">Resend Code</b-button>
                    </b-overlay>
                </div>
                <div class="col-12 text-center mt-1 p-0">
                    <small>Not your registration? <a href="javascript:void(0)" @click.prevent="discardCurrentRegistration">Start a new one.</a></small>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import { BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton, BOverlay } from 'bootstrap-vue'
    import { required } from '@validations'
    export default {
        props: {
            showSubmitOverlay: Boolean,
        },
        components: {
            BCardTitle,
            BCardText,
            BForm,
            BFormGroup,
            BFormInput,
            BButton,
            BOverlay,
            // Validation
            ValidationObserver,
            ValidationProvider
        },
        data() {
            return {
                required,
                otpForm: {
                    verification_code: '',
                },
                bouncedResend: null
            }
        },
        destroyed() {
            clearTimeout(this.bouncedResend)
        },
        methods: {
            submitOtp() {
                this.$emit('submit-otp', this.otpForm)
            },
            resendOtp() {
                if (this.bouncedResend) {
                    clearTimeout(this.bouncedResend)
                }
                this.bouncedResend = setTimeout(() => {
                    this.$emit('resend-otp')
                }, 500)
            },
            discardCurrentRegistration() {
                this.$store.dispatch('discardTempRegistration')
                window.location.reload()
            }
        }
    }
</script>