import httpConfigHelper from './config.js'
import app from '../app'
export default class Register {
    httpConfig = null
    axiosIns = null
    header = null
    constructor() {
        this.httpConfig = new httpConfigHelper
        this.axiosIns = this.httpConfig.useAxios()
        this.header = this.httpConfig.headerConfig()
    }

    requestOtp(data) {
        let httpOptions = this.header
        return this.axiosIns.post(app.requestOtp, data, httpOptions)
    }

    register(data) {
        let httpOptions = this.header
        return this.axiosIns.post(app.register, data, httpOptions)
    }
}