var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Make your app management easy and fun! ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Mobile Number","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile Number","rules":"required|integer|min:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-mobile","name":"register-mobile","state":errors.length > 0 ? false:null,"placeholder":"639001234567"},model:{value:(_vm.registrationForm.mobile),callback:function ($$v) {_vm.$set(_vm.registrationForm, "mobile", $$v)},expression:"registrationForm.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"First Name","label-for":"register-firstname"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false:null,"placeholder":"First name"},model:{value:(_vm.registrationForm.firstname),callback:function ($$v) {_vm.$set(_vm.registrationForm, "firstname", $$v)},expression:"registrationForm.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false:null,"placeholder":"Last name"},model:{value:(_vm.registrationForm.lastname),callback:function ($$v) {_vm.$set(_vm.registrationForm, "lastname", $$v)},expression:"registrationForm.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Gender","label-for":"register-gender"}},[_c('b-form-radio-group',{attrs:{"id":"register-gender","name":"register-gender","options":_vm.genderOptions,"inline":"","value-field":"value","text-field":"text","disabled-field":"disabled"},on:{"click":function($event){$event.preventDefault();}},model:{value:(_vm.registrationForm.gender),callback:function ($$v) {_vm.$set(_vm.registrationForm, "gender", $$v)},expression:"registrationForm.gender"}})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.registrationForm.userEmail),callback:function ($$v) {_vm.$set(_vm.registrationForm, "userEmail", $$v)},expression:"registrationForm.userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{ref:"password",staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"Password"},model:{value:(_vm.registrationForm.password),callback:function ($$v) {_vm.$set(_vm.registrationForm, "password", $$v)},expression:"registrationForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password-confirmation","label":"Confirm password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password-confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password-confirmation","placeholder":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Agreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","state":errors.length > 0 ? false:null,"name":"checkbox-1"},model:{value:(_vm.registrationForm.status),callback:function ($$v) {_vm.$set(_vm.registrationForm, "status", $$v)},expression:"registrationForm.status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)]}}])})],1),_c('b-overlay',{staticClass:"d-inline-block container-fluid p-0",attrs:{"show":_vm.showSubmitOverlay,"rounded":"sm","spinner-variant":"primary","spinner-small":""}},[_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[_vm._v("Sign up")])],1)],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login-v2'}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }