<template>
    <div>
        <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Adventure starts here 🚀
        </b-card-title>
        <b-card-text class="mb-2">
            Make your app management easy and fun!
        </b-card-text>
        <!-- form -->
        <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
            <!-- mobile -->
            <b-form-group
                label="Mobile Number"
                label-for="register-mobile"
            >
                <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                rules="required|integer|min:12"
                >
                <b-form-input
                    id="register-mobile"
                    v-model="registrationForm.mobile"
                    name="register-mobile"
                    :state="errors.length > 0 ? false:null"
                    placeholder="639001234567"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- First Name -->
            <b-form-group label="First Name" label-for="register-firstname">
                <validation-provider #default="{ errors }" name="First Name" rules="required">
                    <b-form-input id="register-firstname" name="register-firstname" v-model="registrationForm.firstname" :state="errors.length > 0 ? false:null" placeholder="First name"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- Last Name -->
            <b-form-group label="Last Name" label-for="register-lastname">
                <validation-provider #default="{ errors }" name="Last Name" rules="required">
                    <b-form-input id="register-lastname" name="register-lastname" v-model="registrationForm.lastname" :state="errors.length > 0 ? false:null" placeholder="Last name"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- Gender -->
            <b-form-group label="Gender" label-for="register-gender">
                <b-form-radio-group @click.prevent id="register-gender" name="register-gender" v-model="registrationForm.gender" :options="genderOptions" inline value-field="value" text-field="text" disabled-field="disabled"/>
            </b-form-group>

            <!-- email -->
            <b-form-group
                label="Email"
                label-for="register-email"
            >
                <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
                >
                <b-form-input
                    id="register-email"
                    v-model="registrationForm.userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
                label-for="register-password"
                label="Password"
            >
                <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
                vid="password"
                >
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                    <b-form-input
                    id="register-password"
                    v-model="registrationForm.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    name="register-password"
                    placeholder="Password"
                    ref="password"
                    />
                    <b-input-group-append is-text>
                    <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                    />
                    </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

                <!-- Password Confirmation -->
                <b-form-group
                label-for="register-password-confirmation"
                label="Confirm password"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required|confirmed:password"
                    >
                        <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        >
                        <b-form-input
                            id="register-password-confirmation"
                            class="form-control-merge"
                            v-model="confirmPassword"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false:null"
                            name="register-password-confirmation"
                            placeholder="Confirm Password"
                        />
                        <b-input-group-append is-text>
                            <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                            />
                        </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

            <b-form-group>
                <validation-provider #default="{ errors }" name="Agreement" rules="required">
                    <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="registrationForm.status"
                    :state="errors.length > 0 ? false:null"
                    name="checkbox-1"
                    >
                        I agree to
                        <b-link>privacy policy & terms</b-link>
                    </b-form-checkbox>
                </validation-provider>
            </b-form-group>

            <!-- <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validateForm"
            >
                <b-overlay :show="isSubmitted" variant="transparent" blur="2px"></b-overlay>
                Sign up
            </b-button> -->

            <b-overlay :show="showSubmitOverlay" rounded="sm" spinner-variant="primary" spinner-small class="d-inline-block container-fluid p-0">
                <!-- <b-button type="submit" variant="primary" block @click="validationForm">Sign in</b-button> -->
                <b-button variant="primary" block type="submit" @click.prevent="validateForm">Sign up</b-button>
            </b-overlay>
            </b-form>
        </validation-observer>

        <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login-v2'}">
            <span>&nbsp;Sign in instead</span>
            </b-link>
        </p>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardTitle, BCardText, BFormRadioGroup, BOverlay } from 'bootstrap-vue'
    import { required, email, confirmed, alpha } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
    export default {
        props: {
            showSubmitOverlay: Boolean
        },
        components: {
            // Bootstrap
            BRow,
            BCol,
            BLink,
            BButton,
            BForm,
            BFormCheckbox,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BInputGroupAppend,
            BCardTitle,
            BCardText,
            BFormRadioGroup,
            BOverlay,
            // Validation
            ValidationProvider,
            ValidationObserver,
            FeatherIcon,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                registrationForm: {
                    status: '',
                    mobile: '',
                    firstname: '',
                    lastname: '',
                    gender: '',
                    userEmail: '',
                    password: '',
                    verification_code: ''
                },
                confirmPassword: '',
                genderOptions: [
                    { text: 'Male', value: 'male', disabled: false },
                    { text: 'Female', value: 'female', disabled: false },
                ],
                // Validation
                required,
                email,
                confirmed,
                alpha
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
        },
        created() {},
        mounted() {},
        methods: {
            validateForm() {
                this.$emit('toggle-overlay')
                // Check if the agreement box is ticked.
                if (!this.registrationForm.status) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Invalid Form',
                            text: 'Kindly correct all errors and try again.',
                            icon: 'EditIcon',
                            variant: 'danger'
                        }
                    })
                    this.$emit('toggle-overlay')
                }
                else {
                    // Perform validation.
                    this.$refs.registerForm.validate().then(success => {
                        if (success) {
                            // Emit the submit-registration event to tell the parent that
                            // the component's form is valid and ready to be submitted.
                            this.$emit('submit-registration', this.registrationForm)
                        }
                        else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Invalid Form',
                                    text: 'Kindly correct all errors and try again.',
                                    icon: 'EditIcon',
                                    variant: 'danger'
                                }
                            })
                            this.$emit('toggle-overlay')
                        }
                    }).catch(ex => {
                        console.log('Error validating form: ', ex)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Form cannot be validated',
                                text: '',
                                icon: 'EditIcon',
                                variant: 'danger'
                            }
                        })
                        this.$emit('toggle-overlay')
                    })
                }
            }
        }
    }
</script>